import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';
import api from '../config';


const poolData = {
  UserPoolId: api.ustPoolId || 123,
  ClientId: api.ustClientId || 123,
  Storage: new CookieStorage({secure: false, domain: api.ustCookieDomain || "abc.com"}),
};

export default new CognitoUserPool(poolData);
