import { AxiosRequestConfig } from 'axios';
import { getUToken } from './auth.helper';
import { message } from 'antd';
import {axiosRenderApi, axiosDashboardServerless, axiosStudioApi, axiosUst, axiosVumuServerless,authServiceAxios, axiosDashboardServiceApi} from './interceptor';

interface IProps {
    apiObject?: any
    url: AxiosRequestConfig['url']
    payload: AxiosRequestConfig['data']
    method: AxiosRequestConfig['method']
    token?: string
}

export const callRenderApi = async ({ url, payload, method }: IProps) => {
    return initiateApiCall({ apiObject: axiosRenderApi, url, payload, method })
}

export const callApiGateway = async ({ url, payload, method }: IProps) => {
    return initiateApiCall({ apiObject: axiosDashboardServerless, url, payload, method })
}
export const callApiTokenPayload = async ({ url, payload, method }: IProps) => {
    return initiateApiCall({ apiObject: axiosDashboardServerless, url, payload, method })
}

export const callStudioApi = async ({ url, payload, method }: IProps) => {
    return initiateApiCall({ apiObject: axiosStudioApi, url, payload, method })
}
export const callVumuServerlessApi = async ({ url, payload, method }: IProps) => {
    return initiateApiCall({ apiObject: axiosVumuServerless, url, payload, method })
}

export const callUstApi = async ({ url, payload, method }: IProps) => {
    return initiateApiCall({ apiObject: axiosUst, url, payload, method })
}
export const callAuthServiceApi = async ({ url, payload, method }: IProps) => {
    return initiateApiCall({ apiObject: authServiceAxios, url, payload, method })
}
export const callDashboardServiceApi = async ({ url, payload, method }: IProps) => {
    return initiateApiCall({ apiObject: axiosDashboardServiceApi, url, payload, method })
}

const initiateApiCall = async ({ apiObject, url, payload, method }: IProps) => {
    const token = getUToken()
    return await callApiIntercept({ apiObject, url, payload, method, token })
}

const callApiIntercept = async ({ apiObject, url, payload, method, token }: IProps) => {
    try {
        switch (method) {
            case 'GET':
            case 'DELETE':
            {
                let obj = {
                    url: url,
                    method: method,
                    params: payload,
                    headers: token ? {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    } : {'Content-Type': 'application/json'}
                };
                return await apiObject(obj);
            }
            case 'PATCH':
            case 'POST':
            case 'PUT': {
                let obj = {
                    url: url,
                    method: method,
                    headers: token ? {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    } : {'Content-Type': 'application/json'},
                    data: payload
                };
                return await apiObject(obj)
            }
            default: {
                break
            }
        }

    } catch (error) {
        console.error('something went wrong')
        return null
    }

}

// export const callAuthServiceApiWithToken = async ({ url, payload, method, token }: IProps) => {
//     return method === 'GET' ?
//         await authServiceAxios({
//             url: url,
//             method: method,
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'application/json'
//             },
//         }).then((res: any) => {
//             return res;
//         }).catch((err: any) => {
//             message.error("something went wrong");
//         }) : await authServiceAxios({
//             url: url,
//             method: method,
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'application/json'
//             },
//             data: payload
//         }).then((res: any) => {
//             return res.status === 200 ? {userDetails: res.data.data, message: "Successfully fetched user Details"} : null;
//         }).catch((err: any) => {
//             console.log(err)
//             return err ? {userDetails: null,  message: err.response.data.message} : null
//
//             // message.error("something went wrong");
//         });
// }
// export const callAuthServiceApiWithoutToken = async ({ url, payload, method }: IProps) => {
//     return method === 'GET' ?
//         await authServiceAxios({
//             url: url,
//             method: method,
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//         }).then((res: any) => {
//             return res;
//         }).catch((err: any) => {
//             message.error("something went wrong");
//         }) : await authServiceAxios({
//             url: url,
//             method: method,
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             data: payload
//         }).then((res: any) => {
//             return res.data;
//         }).catch((err: any) => {
//             message.error("something went wrong");
//         });
// }
//
// export const callApiWithToken = async ({ url, payload, method, token }: IProps) => {
//   return method === 'GET' ?
//     await axiosApi({
//       url: url,
//       method: method,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json'
//       },
//     }).then((res: any) => {
//       return res.data;
//     }).catch((err: any) => {
//       message.error("something went wrong");
//     }) : await axiosApi({
//       url: url,
//       method: method,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json'
//       },
//       data: payload
//     }).then((res: any) => {
//       return res;
//     }).catch((err: any) => {
//       message.error("something went wrong");
//     });
// }
//
// export const callApiWithoutToken = async ({ url, payload, method }: IProps) => {
//   return method === 'GET' ?
//     await axiosApi({
//       url: url,
//       method: method,
//       headers: {
//         'Content-Type': 'application/json'
//       },
//     }).then((res: any) => {
//       return res.data;
//     }).catch((err: any) => {
//       message.error("something went wrong");
//     }) : await axiosApi({
//       url: url,
//       method: method,
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       data: payload
//     }).then((res: any) => {
//       return res;
//     }).catch((err: any) => {
//       message.error("something went wrong");
//     });
// }
//
// export const callApiGatewayWithToken = async ({ url, payload, method, token }: IProps) => {
//   return method === 'GET' ?
//     await axiosApiGateway({
//       url: url,
//       method: method,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json'
//       },
//     }).then((res: any) => {
//       return res.data;
//     }).catch((err: any) => {
//       message.error("something went wrong");
//     }) : await axiosApiGateway({
//       url: url,
//       method: method,
//       headers: {
//         'Authorization': 'Bearer ' + token,
//         'Accept': 'application/json',
//         'Content-Type': 'application/json'
//       },
//       data: payload
//     }).then((res: any) => {
//       console.log({ res });
//       return res;
//     }).catch((err: any) => {
//         console.log(err)
//           return null
//       message.error("something went wrong");
//     });
// }
//
// export const callApiGatewayWithoutToken = async ({ url, payload, method }: IProps) => {
//   return method === 'GET' ?
//     await axiosApiGateway({
//       url: url,
//       method: method,
//       headers: {
//         'Content-Type': 'application/json'
//       },
//     }).then((res: any) => {
//       return res.data;
//     }).catch((err: any) => {
//       message.error("something went wrong");
//     }) : await axiosApiGateway({
//       url: url,
//       method: method,
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       data: payload
//     }).then((res: any) => {
//       return res;
//     }).catch((err: any) => {
//       message.error("something went wrong");
//     });
// }
//
// export const callStudioApiWithToken = async ({ url, payload, method, token }: IProps) => {
//   return method === 'GET' ?
//     await axiosStudioApi({
//       url: url,
//       method: method,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json'
//       },
//     }).then((res: any) => {
//       return res.data;
//     }).catch((err: any) => {
//       message.error("something went wrong");
//     }) : await axiosStudioApi({
//       url: url,
//       method: method,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json'
//       },
//       data: payload
//     }).then((res: any) => {
//       return res;
//     }).catch((err: any) => {
//       message.error("something went wrong");
//     });
// }
//
// export const callStudioApiWithoutToken = async ({ url, payload, method }: IProps) => {
//   return method === 'GET' ?
//     await axiosStudioApi({
//       url: url,
//       method: method,
//       headers: {
//         'Content-Type': 'application/json'
//       },
//     }).then((res: any) => {
//       return res.data;
//     }).catch((err: any) => {
//       message.error("something went wrong");
//     }) : await axiosStudioApi({
//       url: url,
//       method: method,
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       data: payload
//     }).then((res: any) => {
//       return res;
//     }).catch((err: any) => {
//       message.error("something went wrong");
//     });
// }
//
// export const callVumuServerlessApiWithToken = async ({ url, payload, method, token }: IProps) => {
//   return method === 'GET' ?
//       await axiosVumuServerless({
//         url: url,
//         method: method,
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json'
//         },
//       }).then((res: any) => {
//         return res.data;
//       }).catch((err: any) => {
//         message.error("something went wrong");
//       }) : await axiosVumuServerless({
//         url: url,
//         method: method,
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json'
//         },
//         data: payload
//       }).then((res: any) => {
//         return res;
//       }).catch((err: any) => {
//         message.error("something went wrong");
//       });
// }
//
// export const callVumuServerlessApiWithoutToken = async ({ url, payload, method }: IProps) => {
//   return method === 'GET' ?
//       await axiosVumuServerless({
//         url: url,
//         method: method,
//         headers: {
//           'Content-Type': 'application/json'
//         },
//       }).then((res: any) => {
//         return res.data;
//       }).catch((err: any) => {
//         message.error("something went wrong");
//       }) : await axiosVumuServerless({
//         url: url,
//         method: method,
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         data: payload
//       }).then((res: any) => {
//         return res;
//       }).catch((err: any) => {
//         message.error("something went wrong");
//       });
// }
//
//
// export const callUstApiWithToken = async ({ url, payload, method, token }: IProps) => {
//     return method === 'GET' ?
//         await axiosUst({
//             url: url,
//             method: method,
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'application/json'
//             },
//         }).then((res: any) => {
//             return res;
//         }).catch((err: any) => {
//             message.error("something went wrong");
//         }) : await axiosUst({
//             url: url,
//             method: method,
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'application/json'
//             },
//             data: payload
//         }).then((res: any) => {
//             return res.status === 200 ? {userDetails: res.data.data, message: "Successfully fetched user Details"} : null;
//         }).catch((err: any) => {
//             console.log(err)
//             return err ? {userDetails: null,  message: err.response.data.message} : null
//
//             // message.error("something went wrong");
//         });
// }
//
// export const callUstApiWithoutToken = async ({ url, payload, method }: IProps) => {
//     return method === 'GET' ?
//         await axiosUst({
//             url: url,
//             method: method,
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//         }).then((res: any) => {
//             return res;
//         }).catch((err: any) => {
//             message.error("something went wrong");
//         }) : await axiosUst({
//             url: url,
//             method: method,
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             data: payload
//         }).then((res: any) => {
//             return res.data;
//         }).catch((err: any) => {
//             message.error("something went wrong");
//         });
// }
