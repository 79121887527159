export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const LOADED_SUCCESS = 'LOADED_SUCCESS';
export const LOADING_APP = 'LOADING_APP';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const UPGRADE_MODAL_VISIBLE = 'UPGRADE_MODAL_VISIBLE';
export const GET_MONTHLY_VIDEOS_REQUEST = 'GET_MONTHLY_VIDEOS_REQUEST';
export const GET_MONTHLY_VIDEOS_REQUEST_SUCCESS = 'GET_MONTHLY_VIDEOS_REQUEST_SUCCESS';
export const GET_ALL_SUBSCRIPTIONS = 'GET_ALL_SUBSCRIPTIONS';
export const GET_ALL_SUBSCRIPTIONS_SUCCESS = 'GET_ALL_SUBSCRIPTIONS_SUCCESS';
export const SET_CONNECTED_APPS = 'SET_CONNECTED_APPS';


interface IProps {
  ADD_ACCOUNT_REQUEST: typeof SOCKET_CONNECTED;
  ADD_ACCOUNT_SUCCESS: typeof LOADED_SUCCESS;
  LOADING_APP: typeof LOADING_APP;
  SET_PERMISSIONS: typeof SET_PERMISSIONS;
  UPGRADE_MODAL_VISIBLE: typeof UPGRADE_MODAL_VISIBLE;
  GET_MONTHLY_VIDEOS_REQUEST_SUCCESS: typeof GET_MONTHLY_VIDEOS_REQUEST_SUCCESS;
  GET_ALL_SUBSCRIPTIONS: typeof GET_ALL_SUBSCRIPTIONS;
  GET_ALL_SUBSCRIPTIONS_SUCCESS: typeof GET_ALL_SUBSCRIPTIONS_SUCCESS;
  SET_CONNECTED_APPS: typeof SET_CONNECTED_APPS;
}

export type actionType = IProps
