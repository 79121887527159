import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';


import { callApiGateway, callUstApi } from '../../helper/api.helper';
import * as types from './types';

//saga calls

export function* fetchMonthlyStatisticsSaga() {
  yield takeEvery(types.GET_MONTHLY_VIDEOS_REQUEST, function* ({ payload }: any): SagaIterator {
    try {
      const response = yield call(fetchStatisticsReq, payload);
      yield put({
        type: types.GET_MONTHLY_VIDEOS_REQUEST_SUCCESS,
        payload: response.length > 0 ? response[0] : null
      });
    } catch (error) {
      console.log({ error });

    }
  });
}

export function* getAllSubscriptionsSaga() {
  yield takeEvery(types.GET_ALL_SUBSCRIPTIONS, function* ({ payload, resolve, reject }: any): SagaIterator {
    try {
      const response = yield call(fetchSubscriptionsReq, payload);
      yield put({
        type: types.GET_ALL_SUBSCRIPTIONS_SUCCESS,
        payload: response['userDetails']
      });
      resolve();
    } catch (error) {
      console.log({ error });
      reject();
    }
  });
}


//api Calls
async function fetchStatisticsReq(payload: any) {
  return await callApiGateway({ url: '/get-statistics', payload, method: 'POST' })
}

async function fetchSubscriptionsReq(payload: any) {
  return await callUstApi({ url: '/get-subscription', payload, method: 'POST' })
}


export default function* fileBrowserSaga() {
  yield all([fork(fetchMonthlyStatisticsSaga), fork(getAllSubscriptionsSaga)]);
}
