/**
 *
 * @param {*} data
 * Default render video model which will contain all the nesassary attributes for the payload used in backend.
 */
export class RenderAudioModel {
    id: string;
    start: number;
    end: number;
    showtime: number;
    src: string;
    volume: number;

    constructor(data: any) {
        this.id = data.id
        this.start = data.targetStart
        this.end = data.targetEnd
        this.showtime = data.showTime
        this.src = data.src
        this.volume = data.volume
    }
}


