import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';
import * as types from './types';
import { message } from 'antd';
import {callDashboardServiceApi} from '../../helper/api.helper';


//saga calls
export function* fetchAgencyWorkspaceSaga() {
  yield takeEvery(types.FETCH_AGENCY_WORKSPACES, function* ({ payload, resolve, reject }: any): SagaIterator {
    try {
      const response = yield call(fetchAllWorkspaces, payload);
      console.log({ response });
      yield put({
        type: types.SET_AGENCY_WORKSPACES,
        payload: response
      });
      resolve(response)
    } catch (error) {
      message.error("Error during submittion of account creation");
      reject(error)
    }
  });
}
export function* createSubAccountWorkspaceSaga() {
  yield takeEvery(types.CREATE_SUB_WORKSPACE, function* ({ payload, resolve, reject }: any): SagaIterator {
    try {
      const response = yield call(createSubAccountWorkspaceApi, payload);
      console.log({ response });
      message.success("Successfully created new account.");
      resolve(response)
    } catch (error) {
      message.error("Error during submition of account creation.");
      reject(error)
    }
  });
}
export function* deleteSubAccountWorkspaceSaga() {
  yield takeEvery(types.DELETE_SUB_WORKSPACE, function* ({ payload, resolve, reject }: any): SagaIterator {
    try {
      const response = yield call(deleteSubAccountWorkspaceApi, payload);
      console.log({ response });
      message.success("Successfully deleted Account.");
      resolve(response)
    } catch (error) {
      message.error("Error during submittion of account creation.");
      reject(error)
    }
  });
}
export function* associateUserWorkspaceSaga() {
  yield takeEvery(types.ASSOCIATE_USER_TO_WORKSPACE, function* ({ payload, resolve, reject }: any): SagaIterator {
    try {
      const response = yield call(associateUserWorkspaceApi, payload);
      console.log({ response });
      message.success("Successfully Added user.");
      resolve(response)
    } catch (error) {
      message.error("Error during submitting account creation.");
      reject(error)
    }
  });
}
export function* removeUserFromWorkspaceSaga() {
  yield takeEvery(types.REMOVE_USER_FROM_WORKSPACE, function* ({ payload, resolve, reject }: any): SagaIterator {
    try {
      const response = yield call(removeUserFromWorkspaceApi, payload);
      console.log({ response });
      message.success("Successfully Removed User.");
      resolve(response)
    } catch (error) {
      message.error("Error during removing a user.");
      reject(error)
    }
  });
}



//api Calls
async function fetchAllWorkspaces(payload: any) {
  return await callDashboardServiceApi({ url: 'workspace/fetch-agency-workspace', payload, method: 'POST' })
}

async function createSubAccountWorkspaceApi(payload: any) {
  return await callDashboardServiceApi({ url: 'workspace/create-sub-workspace', payload, method: 'POST' })
}

async function deleteSubAccountWorkspaceApi(payload: any) {
  return await callDashboardServiceApi({ url: 'workspace/delete-sub-workspace', payload, method: 'POST' })
}

async function associateUserWorkspaceApi(payload: any) {
  return await callDashboardServiceApi({ url: '/workspace/associate-user', payload, method: 'POST' })
}
async function removeUserFromWorkspaceApi(payload: any) {
  return await callDashboardServiceApi({ url: '/workspace/remove-user', payload, method: 'POST' })
}


async function fetchAllUsersReq(payload: any) {
  return await callDashboardServiceApi({ url: '/fetch-agency-users', payload, method: 'POST' })
}

async function updateUserStatusReq(payload: any) {
  return await callDashboardServiceApi({ url: '/update-active-status/', payload, method: 'POST' })
}

async function updateAccountReq(payload: any) {
  return await callDashboardServiceApi({ url: '/update-agency', payload, method: 'POST' })
}

async function updateUserAccountReq(payload: any) {
  return await callDashboardServiceApi({ url: '/update-user', payload, method: 'POST' })
}

async function fetchPlansReq(payload: any) {
  return await callDashboardServiceApi({ url: '/fetch-plans', payload, method: 'POST' })
}

async function resendInviteEmailReq(payload: any) {
  return await callDashboardServiceApi({ url: '/request-reset-email/', payload, method: 'POST' })
}

async function changeUserStatusReq(payload: any) {
  return await callDashboardServiceApi({ url: '/update-user-active', payload, method: 'POST' })
}

async function fetchWorkspace(payload:any) {
  return await callDashboardServiceApi({ url: '/get-workspace', payload, method: 'POST' })
}

export default function* rootSaga() {
  yield all([
    fork(fetchAgencyWorkspaceSaga),
    fork(createSubAccountWorkspaceSaga),
    fork(deleteSubAccountWorkspaceSaga),
    fork(associateUserWorkspaceSaga),
    fork(removeUserFromWorkspaceSaga),
  ]);
}
