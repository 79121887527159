export const FETCH_AGENCY_WORKSPACES = 'FETCH_AGENCY_WORKSPACES';
export const CREATE_SUB_WORKSPACE = 'CREATE_SUB_WORKSPACE';
export const DELETE_SUB_WORKSPACE = 'DELETE_SUB_WORKSPACE';
export const ASSOCIATE_USER_WORKSPACE = 'ASSOCIATE_USER_WORKSPACE';
export const SET_AGENCY_WORKSPACES = 'SET_AGENCY_WORKSPACES';
export const SET_ASSOCIATED_USERS = 'SET_ASSOCIATED_USERS';
export const SET_CURRENT_WORKSPACE = 'SET_CURRENT_WORKSPACE';
export const ASSOCIATE_USER_TO_WORKSPACE = 'ASSOCIATE_USER_TO_WORKSPACE';
export const REMOVE_USER_FROM_WORKSPACE = 'REMOVE_USER_FROM_WORKSPACE';



interface IProps {
  FETCH_AGENCY_WORKSPACES: typeof FETCH_AGENCY_WORKSPACES;
  SET_AGENCY_WORKSPACES: typeof SET_AGENCY_WORKSPACES;
  ASSOCIATE_USER_TO_WORKSPACE: typeof ASSOCIATE_USER_TO_WORKSPACE;
  SET_CURRENT_WORKSPACE: typeof SET_CURRENT_WORKSPACE;
  REMOVE_USER_FROM_WORKSPACE: typeof REMOVE_USER_FROM_WORKSPACE;

}

export type actionType = IProps
