import * as types from './types';
import { Reducer } from 'redux';


interface actionType {
  type: string,
  payload?: any
}



interface initialStateProps {
  agencyWorkspaces: any | null;
  associatedUsers: any | null;
  currentWorkspace: any | null;
}

const initState: initialStateProps = {
  agencyWorkspaces: null,
  associatedUsers: null,
  currentWorkspace: null,
}

const accountReducer: Reducer<initialStateProps, actionType> = (state = initState, action) => {
  switch (action.type) {
  case types.SET_AGENCY_WORKSPACES:
    return {
      ...state,
      agencyWorkspaces: action.payload
    }
  case types.SET_ASSOCIATED_USERS:
    return {
      ...state,
      associatedUsers: action.payload
    }
  case types.SET_CURRENT_WORKSPACE:
    return {
      ...state,
      currentWorkspace: action.payload
    }
    // case UPDATE_WORKSPACE:
    //   return {
    //     ...state,
    //     currentWorkspaceSettings:  action.payload
    //   }
    // case FETCH_ALL_AGENCIES_SUCCESS:
    //   console.log(action.payload)
    //   return {
    //     ...state,
    //     accounts: convertArrayToDict(action.payload, "id"),
    //     masterAccount: [...action.payload].filter((acc: any) => !acc.parentId)[0]
    //   }
    // case SET_CURRENT_ACCOUNT:
    //   return {
    //     ...state,
    //     currentAccount: action.payload
    //   }
    // case FETCH_ALL_USERS_SUCCESS:
    //   return {
    //     ...state,
    //     users: convertArrayToDict(action.payload, "id")
    //   }
    // case NEW_USER_ACCOUNT_CREATED:
    //   return {
    //     ...state,
    //     users: updateDict(state.users, action.payload, "id")
    //   }
    // case FETCH_PLANS_SUCCESS:
    //   return {
    //     ...state,
    //     plansOption: planDict(action.payload)
    //   }
    // case UPDATE_USER_ACCOUNT_SUCCESS:
    //   return {
    //     ...state,
    //     users: updateDict(state.users, action.payload, "id")
    //   }
    // case UPDATE_ACCOUNT_SUCCESS:
    //   return {
    //     ...state,
    //     accounts: updateDict(state.accounts, action.payload, "id")
    //   }
    // case DELETE_USER_ACCOUNT_SUCCESS:
    //   console.log(action.payload);
    //   return {
    //     ...state,
    //     users: deleteDict(state.users, "userId", action.payload.userId)
    //   }
    // case DELETE_ACCOUNT_SUCCESS:
    //   return {
    //     ...state,
    //     accounts: deleteDict(state.accounts, "id", action.payload.agencyId)
    //   }
    // case CHANGE_USER_STATUS_SUCCESS:
    //   return {
    //     ...state,
    //     users: updateDict(state.users, action.payload, "id")
    //   }
  default:
    return state;
  }
}



export default accountReducer;
