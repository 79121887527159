export const FETCH_ALL_PRO_AND_DIR = 'FETCH_ALL_PRO_AND_DIR';
export const FETCH_ALL_PRO_AND_DIR_SUCCESS = 'FETCH_ALL_PRO_AND_DIR_SUCCESS';
export const CREATE_NEW_DIR = 'CREATE_NEW_DIR';
export const CREATE_NEW_DIR_SUCCESS = 'CREATE_NEW_DIR_SUCCESS';
export const UPDATE_DIR = 'UPDATE_DIR';
export const UPDATE_DIR_SUCCESS = 'UPDATE_DIR_SUCCESS';
export const DELETE_DIR = 'DELETE_DIR';
export const DELETE_DIR_SUCCESS = 'DELETE_DIR_SUCCESS';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const SHOW_PLAY_BOOK_POPUP = 'SHOW_PLAY_BOOK_POPUP';
export const SET_CURRENT_PARENT_DIRECTORY = 'SET_CURRENT_PARENT_DIRECTORY';
export const UPDATE_FOLDER_CHAIN = 'UPDATE_FOLDER_CHAIN';
export const EMPTY_FOLDER_CHAIN = 'EMPTY_FOLDER_CHAIN';
export const UPDATE_CURRENT_DIRECTORY = 'UPDATE_CURRENT_DIRECTORY';
export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_VUMU_PROJECT_UPLOAD = 'ADD_VUMU_PROJECT_UPLOAD';
export const SHOW_IFRAME = 'SHOW_IFRAME';
export const FB_LOADING = 'FB_LOADING';
export const RENDER_VIDEO = 'RENDER_VIDEO';
export const ADD_PROJECT_LOCAL = 'ADD_PROJECT_LOCAL';
export const ADD_PROJECT_KEEVI = 'ADD_PROJECT_KEEVI';
export const ADD_PROJECT_KEEVI_SUCCESS = 'ADD_PROJECT_KEEVI_SUCCESS';
export const ADD_PROJECT_VUMU = 'ADD_PROJECT_VUMU';
export const ADD_PROJECT_VUMU_SUCCESS = 'ADD_PROJECT_VUMU_SUCCESS';
export const UPDATE_PROJECT_VUMU = 'UPDATE_PROJECT_VUMU';
export const UPDATE_PROJECT_VUMU_SUCCESS = 'UPDATE_PROJECT_VUMU_SUCCESS';
export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const SET_PROJECTS_TO_EMPTY = 'SET_PROJECTS_TO_EMPTY';
export const GET_STATISTICS_REQUEST = 'GET_STATISTICS_REQUEST';
export const GET_STATISTICS_REQUEST_SUCCESS = 'GET_STATISTICS_REQUEST_SUCCESS';
export const CREATE_ONBOARDING = 'CREATE_ONBOARDING';
export const ADD_ONBOARDING_SETTINGS = 'ADD_ONBOARDING_SETTINGS';

export const ADD_PROJECT_BLOG_VUMU = 'ADD_PROJECT_BLOG_VUMU';
export const FETCH_PROJECT_BLOG_VUMU = 'FETCH_PROJECT_BLOG_VUMU';

export const UPDATE_WORKSPACE = 'UPDATE_WORKSPACE';
export const SET_WORKSPACE_ASSOCIATION = 'SET_WORKSPACE_ASSOCIATION';
export const FETCH_WORKSPACE_ONLY = 'FETCH_WORKSPACE_ONLY';
export const FETCH_WORKSPACE_ASSOCIATION = 'FETCH_WORKSPACE_ASSOCIATION';
export const CALL_UPDATE_WORKSPACE = 'CALL_UPDATE_WORKSPACE';
export const CALL_UPDATE_DOMAIN = 'CALL_UPDATE_DOMAIN';
export const SET_NEXT_PAGE = 'SET_NEXT_PAGE';

export const FETCH_ALL_PRO_REQ = 'FETCH_ALL_PRO_REQ';
export const FETCH_ALL_PRO_SUCCESS = 'FETCH_ALL_PRO_SUCCESS';
export const FETCH_ALL_DIR_REQ = 'FETCH_ALL_DIR_REQ';
export const FETCH_ALL_DIR_SUCCESS = 'FETCH_ALL_DIR_SUCCESS';
export const FETCH_ALL_DIR_WRK_REQ = 'FETCH_ALL_DIR_WRK_REQ';
export const FETCH_ALL_DIR_WRK_SUCCESS = 'FETCH_ALL_DIR_WRK_SUCCESS';
export const GET_COMMENTS_COUNT_REQ = 'GET_COMMENTS_COUNT_REQ';
export const GET_COMMENTS_COUNT_SUCCESS = 'GET_COMMENTS_COUNT_SUCCESS';
export const GET_USER_B_WORKSPACE = 'GET_USER_B_WORKSPACE';

export const UPDATE_PROJECT_VUMU_SERVERLESS = 'UPDATE_PROJECT_VUMU_SERVERLESS';

interface IProps {
  FETCH_ALL_PRO_AND_DIR: typeof FETCH_ALL_PRO_AND_DIR;
  FETCH_ALL_PRO_AND_DIR_SUCCESS: typeof FETCH_ALL_PRO_AND_DIR_SUCCESS;
  CREATE_NEW_DIR: typeof CREATE_NEW_DIR;
  CREATE_NEW_DIR_SUCCESS: typeof CREATE_NEW_DIR_SUCCESS;
  UPDATE_DIR: typeof UPDATE_DIR;
  UPDATE_DIR_SUCCESS: typeof UPDATE_DIR_SUCCESS;
  DELETE_DIR: typeof DELETE_DIR;
  DELETE_DIR_SUCCESS: typeof DELETE_DIR_SUCCESS;
  UPDATE_PROJECT: typeof UPDATE_PROJECT;
  UPDATE_PROJECT_VUMU_SERVERLESS: typeof UPDATE_PROJECT_VUMU_SERVERLESS;
  UPDATE_PROJECT_SUCCESS: typeof UPDATE_PROJECT_SUCCESS;
  DELETE_PROJECT: typeof DELETE_PROJECT;
  DELETE_PROJECT_SUCCESS: typeof DELETE_PROJECT_SUCCESS;
  SET_CURRENT_PARENT_DIRECTORY: typeof SET_CURRENT_PARENT_DIRECTORY;
  UPDATE_FOLDER_CHAIN: typeof UPDATE_FOLDER_CHAIN;
  UPDATE_CURRENT_DIRECTORY: typeof UPDATE_CURRENT_DIRECTORY;
  SHOW_IFRAME: typeof SHOW_IFRAME;
  FB_LOADING: typeof FB_LOADING;
  RENDER_VIDEO: typeof RENDER_VIDEO;
  ADD_PROJECT_KEEVI: typeof ADD_PROJECT_KEEVI;
  ADD_PROJECT_KEEVI_SUCCESS: typeof ADD_PROJECT_KEEVI_SUCCESS;
  ADD_PROJECT_VUMU: typeof ADD_PROJECT_VUMU;
  ADD_PROJECT_BLOG_VUMU: typeof ADD_PROJECT_BLOG_VUMU;
  FETCH_PROJECT_BLOG_VUMU: typeof FETCH_PROJECT_BLOG_VUMU;
  ADD_PROJECT_VUMU_SUCCESS: typeof ADD_PROJECT_VUMU_SUCCESS;
  UPDATE_PROJECT_VUMU: typeof UPDATE_PROJECT_VUMU;
  UPDATE_PROJECT_VUMU_SUCCESS: typeof UPDATE_PROJECT_VUMU_SUCCESS;
  SET_PROJECTS_TO_EMPTY: typeof SET_PROJECTS_TO_EMPTY;
  GET_STATISTICS_REQUEST: typeof GET_STATISTICS_REQUEST;
  GET_STATISTICS_REQUEST_SUCCESS: typeof GET_STATISTICS_REQUEST_SUCCESS;
  SET_NEXT_PAGE: typeof SET_NEXT_PAGE;
  FETCH_ALL_PRO_REQ: typeof FETCH_ALL_PRO_REQ;
  FETCH_ALL_PRO_SUCCESS: typeof FETCH_ALL_PRO_SUCCESS;
  FETCH_ALL_DIR_REQ: typeof FETCH_ALL_DIR_REQ;
  FETCH_ALL_DIR_SUCCESS: typeof FETCH_ALL_DIR_SUCCESS;
  FETCH_ALL_DIR_WRK_REQ: typeof FETCH_ALL_DIR_WRK_REQ;
  FETCH_ALL_DIR_WRK_SUCCESS: typeof FETCH_ALL_DIR_WRK_SUCCESS;
  SHOW_PLAY_BOOK_POPUP: typeof SHOW_PLAY_BOOK_POPUP
}

export type actionType = IProps
