import React from "react";

export const dropdownIcon = () => {
    return (
        <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.63397 4.5C2.01888 5.16667 2.98113 5.16667 3.36603 4.5L4.66506 2.25C5.04996 1.58333 4.56884 0.75 3.79904 0.75H1.20096C0.431161 0.75 -0.0499637 1.58333 0.334936 2.25L1.63397 4.5Z" fill="black"/>
        </svg>
    )
}
export const linkIcon = () => {
    return (
        <svg
            width="19"
            height="11"
            viewBox="0 0 19 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.79142 5.57138C1.79142 7.18367 3.10198 8.49423 4.71426 8.49423H8.48573V10.2856H4.71431C2.11202 10.2856 0 8.17367 0 5.57138C0 2.96909 2.11202 0.857073 4.71431 0.857073H8.48573V2.64849H4.71431C3.10202 2.64849 1.79142 3.95909 1.79142 5.57138Z"
                fill="#172B4D"
            />
            <path
                d="M13.2002 6.51416H5.65729V4.62843H13.2002V6.51416Z"
                fill="#172B4D"
            />
            <path
                d="M14.1429 10.2856H10.3715V8.49423H14.1429C15.7552 8.49423 17.0658 7.18367 17.0658 5.57138C17.0658 3.95909 15.7552 2.64849 14.1429 2.64849H10.3715V0.857073H14.1429C16.7452 0.857073 18.8572 2.96909 18.8572 5.57138C18.8572 8.17367 16.7452 10.2856 14.1429 10.2856Z"
                fill="#172B4D"
            />
        </svg>
    )
}
export const upgradeBadge = () => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 5C0 2.23858 2.23858 0 5 0H19C21.7614 0 24 2.23858 24 5V19C24 21.7614 21.7614 24 19 24H5C2.23858 24 0 21.7614 0 19V5Z" fill="#06C3FF"/>
            <path d="M11.0473 5.98815C11.3432 5.05984 12.6568 5.05984 12.9527 5.98814L13.7711 8.55475C13.9039 8.97137 14.2921 9.25337 14.7293 9.25096L17.4232 9.23612C18.3975 9.23075 18.8034 10.48 18.012 11.0483L15.8239 12.6198C15.4687 12.8748 15.3205 13.3311 15.4579 13.7463L16.3045 16.3037C16.6107 17.2287 15.548 18.0007 14.7629 17.4237L12.5922 15.8283C12.2399 15.5693 11.7601 15.5693 11.4078 15.8283L9.23711 17.4237C8.45201 18.0007 7.38934 17.2287 7.69553 16.3037L8.54209 13.7463C8.67951 13.3311 8.53126 12.8748 8.17607 12.6198L5.98797 11.0483C5.19657 10.48 5.60247 9.23075 6.5768 9.23612L9.27066 9.25096C9.70795 9.25337 10.0961 8.97137 10.2289 8.55475L11.0473 5.98815Z" fill="white"/>
        </svg>
    )
}
export const upgradeStar = () => {
    return(
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.11289 1.3702C7.62276 0.131407 9.37725 0.13141 9.88711 1.3702L10.9071 3.84856C11.1228 4.37241 11.6151 4.73012 12.1799 4.77329L14.8522 4.97755C16.1879 5.07965 16.7301 6.74827 15.7095 7.61599L13.6676 9.35196C13.236 9.7189 13.048 10.2977 13.1815 10.8482L13.813 13.4528C14.1286 14.7547 12.7092 15.786 11.5686 15.0834L9.28662 13.678C8.80428 13.3809 8.19572 13.3809 7.71338 13.678L5.4314 15.0835C4.29077 15.786 2.87136 14.7547 3.18702 13.4528L3.81853 10.8482C3.95201 10.2977 3.76395 9.71889 3.33237 9.35196L1.29052 7.61599C0.269911 6.74827 0.812082 5.07965 2.1478 4.97755L4.82007 4.77329C5.38491 4.73012 5.87724 4.37241 6.09285 3.84856L7.11289 1.3702Z" fill="#06C3FF"/>
        </svg>
    )
}