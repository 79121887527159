import React from "react";
import { useSelector } from "react-redux";
import {Route, Redirect, BrowserRouter} from "react-router-dom";
import { RootState } from "../redux/store";

interface IProps {
  component: any;
  path: string;
  exact: boolean;
}

const PublicRoute = ({ component: Component, ...rest }: IProps) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.authReducer.isAuthenticated
  );
  const socketConnected = useSelector(
    (state: RootState) => state.appReducer.socketConnected
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PublicRoute;
