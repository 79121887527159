import authReducer from '../auth/reducers';
import browserReducer from '../file-browser/reducers';
import appReducer from '../app/reducers';
import accountReducer from '../accounts/reducers';

export default {
  authReducer,
  browserReducer,
  appReducer,
  accountReducer
}