export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION';
export const SET_PROFILE = 'SET_PROFILE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const UPDATE_ON_BOARDING = 'UPDATE_ON_BOARDING';
export const UPDATE_USER_ATTRIBUTES = 'UPDATE_USER_ATTRIBUTES';
export const GET_OTHER_USER_DETAILS = 'GET_OTHER_USER_DETAILS';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
export const CALL_UPDATE_PROFILE = 'CALL_UPDATE_PROFILE';


interface IProps{
    CHECK_AUTHORIZATION: typeof CHECK_AUTHORIZATION;
    SET_PROFILE: typeof SET_PROFILE;
    LOGIN_REQUEST: typeof LOGIN_REQUEST;
    GET_PERMISSIONS: typeof GET_PERMISSIONS;
    SET_USER_PERMISSIONS: typeof SET_USER_PERMISSIONS;
    CALL_UPDATE_PROFILE: typeof CALL_UPDATE_PROFILE;
}

export type actionType = IProps
