
import { Reducer } from "redux";
import {
  LOADED_SUCCESS,
  SOCKET_CONNECTED,
  LOADING_APP,
  SET_PERMISSIONS,
  UPGRADE_MODAL_VISIBLE,
  GET_MONTHLY_VIDEOS_REQUEST_SUCCESS,
  GET_ALL_SUBSCRIPTIONS_SUCCESS,
  SET_CONNECTED_APPS
} from './types';
import {SHOW_PLAY_BOOK_POPUP} from "../file-browser/types";
import {automationIcon} from "../../helper/svgConstant";

interface actionType {
  type: string;
  payload?: any;
}

interface initalStateProps {
  loaded: boolean,
  socketConnected: boolean;
  isFreeUser: boolean;
  subscription: string | null;
  permissions: any;
  upgradeModalVisible: boolean;
  monthlyStatistics: any;
  allSubscriptions: any;
  connectedApps: any;
  openPlaybooksPopup: boolean;
}

const initState: initalStateProps = {
  loaded: false,
  socketConnected: false,
  isFreeUser: false,
  subscription: null,
  permissions: {},
  upgradeModalVisible: false,
  monthlyStatistics: null,
  allSubscriptions: [],
  openPlaybooksPopup: false,
  connectedApps: [{
    appId: 'automate',
    appName: 'Personalization',
    appLink: 'personalize',
    appThumbnail: 'https://cdn.vumu.io/assets/icons_skining/personalize-icon.png',
    appIcon: 'https://cdn.vumu.io/assets/icons_skining/personalize-app.png', 
    isInstalled: true,
    appDescription: 'Personalized Images give each recipient their own unique image.',
    type: 'INTERNAL'
  },
  {
    appId: 'automations',
    appName: 'Automations',
    appLink: 'automation',
    appThumbnail: 'https://cdn.vumu.io/assets/icons_skining/updated-automation-icon.svg',
    appIcon: automationIcon(),
    svgIcon: true,
    isInstalled: true,
    appDescription: 'Set up automated workflows to save yourself time on repetitive tasks.',
    type: 'INTERNAL'
  },
    {
      appId: 'inbox',
      appName: 'Inbox',
      appLink: 'crm/inbox',
      appThumbnail: 'https://cdn.vumu.io/assets/icons_skining/logo-crm.png',
      appIcon: "https://cdn.vumu.io/assets/crm/crm-inbox.png",
      isInstalled: true,
      appDescription: 'Check inbox for all your messages.',
      type: 'INTERNAL'
    },
    {
      appId: 'crm',
      appName: 'Contacts',
      appLink: 'crm',
      appThumbnail: 'https://cdn.vumu.io/assets/icons_skining/logo-crm.png',
      appIcon: 'https://cdn.vumu.io/assets/icons_skining/crm-app.png',
      isInstalled: true,
      appDescription: 'Manage and follow up with leads all in one place.',
      type: 'INTERNAL'
    },
  ]
}

const appReducer: Reducer<initalStateProps, actionType> = (state = initState, action) => {
  switch (action.type) {
    case LOADED_SUCCESS:
      return Object.assign({}, state, {
        loaded: true
      });
    case LOADING_APP:
      return Object.assign({}, state, {
        loaded: false
      });
    case SHOW_PLAY_BOOK_POPUP:
      return Object.assign({}, state, {
        openPlaybooksPopup: action.payload
      });
    case SOCKET_CONNECTED:
      console.log(action.payload);
      return Object.assign({}, state, {
        socketConnected: true,
        isFreeUser: !['AGENCYOWNER', 'AGENCYUSER'].includes(action.payload.res.subscription),
        subscription: action.payload.res.subscription,
        loaded: true
      });
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload
      };
    case UPGRADE_MODAL_VISIBLE:
      return {
        ...state,
        upgradeModalVisible: action.payload
      };
    case GET_MONTHLY_VIDEOS_REQUEST_SUCCESS:
      return {
        ...state,
        monthlyStatistics: action.payload
      }

    case GET_ALL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        allSubscriptions: action.payload
      }
    case SET_CONNECTED_APPS:
      return {
        ...state,
        connectedApps: action.payload
      }
    default:
      return state;
  }
}

export default appReducer;
