import config from '../config';
import { store } from '../redux/store';

interface IPermission {
    numberOfVideo: number;
    templateIncluded: number;
    videoStorageQuantity: number;
    passwordProtection: boolean;
    canChangeThumbnail: boolean;
    canChangeLogoAndFav: boolean;
    canChangeBackgroundImage: boolean;
    canCreateSubdomain: boolean;
}

interface permissionArrayType {
    [key: number]: IPermission;

}
class PermissionObject implements IPermission {

    numberOfVideo: number = 0;
    templateIncluded: number = 0;
    videoStorageQuantity: number = 0;
    passwordProtection: boolean = false;
    canChangeThumbnail: boolean = false;
    canChangeLogoAndFav: boolean = false;
    canChangeBackgroundImage: boolean = false;
    canCreateSubdomain: boolean = false;

}

export const permissionsArray: permissionArrayType = {
    ...config.PRODUCTS
};


export const generatePermissionJson: (_products: any) => IPermission = (_products) => {
    // console.log({ _products });
    // const products: string[] | any = _products ? _products.split(",") : _products;
    // let permissions: IPermission = new PermissionObject();
    //
    // if (products && products.length > 0) {
    //     const sortedArray = products.map((product: any) => +product).sort((a: any, b: any) => a - b);
    //
    //     sortedArray.map((sArr: any) => {
    //         permissions = {
    //             ...permissionsArray[sArr]
    //         };
    //     })
    // } else {
    //     permissions = { ...permissionsArray[368931] }
    // }
    if(!_products){
        return config.PRODUCTS['basic'];
    }
    return config.PRODUCTS[_products];
}
export const getSubscriptionTitle = (_product: any) => {
    if(!_product){
        const planName =  config.PRODUCTS['basic']['planName'];
        return planName
    }
    const planName =  config.PRODUCTS[_product]['planName'];
    return planName
}

export const checkActionPermissionStatus: (actionName: string) => boolean = (actionName: string) => {
    const state = store.getState();
    const permissions = state.appReducer.permissions;
    if (!!permissions[actionName]) return true;
    else return false;
}

export const checkActionQuantityStatus: (actionName: string, oldQuantity: number) => boolean = (actionName: string, oldQuantity: number) => {
    const state = store.getState();
    const permissions = state.appReducer.permissions;
    if (permissions[actionName] === -1) return true;
    if (permissions[actionName] >= oldQuantity) return true;
    else return false;
}
