import 'antd/dist/antd.css';
import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import './App.scss';
import './global.scss';
import AppLoader from './component/Apploader';
import api from './config';
import { setAmplitudeUserId } from './helper/amplitude';
import connectSocket from "./helper/socket.helper";
import { getSession } from './helper/ust.helper';
import PrivateRoute from './layout/private';
import PublicRoute from './layout/public';
import { UserModel, getFirstNameFromEmail } from './model';
import { upgradeModalVisibiltyAction } from "./redux/app/actions";
import { LOADED_SUCCESS, SET_PERMISSIONS } from './redux/app/types';
import { getPermissions } from "./redux/auth/actions";
import { SET_PROFILE } from './redux/auth/types';
import {
    fetchCategoriesList, fetchWorkspaceAssociations
} from "./redux/file-browser/actions";
import { RootState, store } from './redux/store';
import { getSubscriptionTitle } from './utils/permissions-helper';


const Dashboard = lazy(() => import('./container/Dashboard'));
const Agency = lazy(
    () => import('./container/Accounts'),
);
const Template = lazy(
    () => import('./container/Dashboard/Templates/Templates'),
);
const AppsComponent = lazy(
    () => import('./container/Dashboard/Apps/index'),
);
const CommentsPage = lazy(
    () => import('./container/Dashboard/Apps/Comments/index'),
);
const AutomationPage = lazy(
    () => import('./container/Dashboard/Apps/Automation/index'),
);
const Settings = lazy(
    () => import('./container/Dashboard/Settings'),
);
// const DashboardHtml = lazy(() => import('./component/DashboardHtml'));
// const Tools = lazy(() => import('./container/Tools'));
const Login = lazy(() => import('./container/Login'));
// const BusinessTools = lazy(() => import('./container/BusinessTools'));
const Accounts = lazy(() => import('./container/Accounts'));


interface IProps {
    PrivateRoute?: JSX.Element;
}

const addCrisp = (parsedData: any) => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = api.CRISP_WEBSITE_ID;
    (function () {
        var d = document;
        var s = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = true;
        d.getElementsByTagName('head')[0].appendChild(s);
    })();
    try {
        window.$crisp.push(['set', 'user:email', parsedData.email]);
        window.$crisp.push(['set', 'user:nickname', [parsedData.fullName]]);
        window.$crisp.push(['set', 'user:avatar', [parsedData.picture]])
        window.$crisp.push(["set", "session:segments", [["firstlogin"]]]);
        window.$crisp.push(['set', 'session:data', ["products", getSubscriptionTitle(parsedData.subscription)]])
    } catch (error) {
        console.log(error, 'crisp data insertion error');
    }
}

const updateSimpleBaseAttributes = (parsedData:any) => {
    if (window.simplebase && window.simplebase.updateUserAttributes) {
        window.simplebase.updateUserAttributes(
            {'user:email': parsedData.email,
            'user:nickname': parsedData.fullName,
            'user:avatar': parsedData.picture,
            'session:segments': ['firstlogin'],
            'session:products': [parsedData.subscription]}
        );
    } else {
        console.error('SimpleBase is not initialized yet.');
    }
};
const intitSnoFlake = (parsedData: any) => {
    // Check if SBF is ready
    if (typeof window.SBF !== 'undefined' && typeof window.SBF.getActiveUser === 'function') {
      const el = document.querySelector(".sb-chat") as HTMLElement | null;
      if (el) {
        el.style.display = 'block';
        el.style.transition = 'auto';
      }
      window.SBF.getActiveUser(true, function(user: any) {
        if (!user || user.email !== parsedData.email) {
          // User not found or email doesn't match - Create and log in user
          window.SBF.ajax({
            function: "add-user-and-login",
            settings: {
              profile_image: parsedData.picture || "https://board.support/media/docs/user.png",
              first_name: getFirstNameFromEmail(parsedData.fullName) || "User",
              last_name: '',
              email: parsedData.email,
              password: "12345678"
            },
          }, function(response: any) {
            if (!window.SBF.errorValidation(response)) {
              // Login successful, initialize chat
              window.SBF.loginCookie(response[1]);
              //@ts-ignore
              window.SBF.activeUser(new SBUser(response[0]));
              //@ts-ignore
              SBChat.initChat();
            } else if (response[1] === "duplicate-email") {
              // Duplicate email - Log in existing user and initialize chat
              window.SBF.login(parsedData.email, "12345678", "", "", function() {
                //@ts-ignore
                SBChat.initChat();
              });
            } else {
              console.error("Error:", response);
            }
          });
        } else {
          // User already exists - Initialize chat
          //@ts-ignore
          SBChat.initChat();
        }
      });
    } else {
      setTimeout(() => {
        intitSnoFlake(parsedData)
      }, 5000);
      console.error("SBF library not found or missing required functions.");
    }
  };
const bootApp = async (dispatch: any) => {
    await getSession()
        .then(session => {
            const parsedData = new UserModel( session, session.idToken.jwtToken);
            // set amplitude data
            try {
                setAmplitudeUserId(parsedData.userId)
            }catch (e){
                console.log('error in amplitude setting data',e)
            }
            connectSocket(parsedData.userId, parsedData.email, null);
            store.dispatch({ type: SET_PROFILE, payload: parsedData });
            if(!parsedData.subscription || parsedData.subscription === 'undefined' ) {
                dispatch(upgradeModalVisibiltyAction(true));
            }
            dispatch(fetchWorkspaceAssociations({userId: parsedData?.userId, token: session.idToken.jwtToken})).then(async (res: any) => {
                if (!res) {
                    return
                }
                store.dispatch({ type: LOADED_SUCCESS });
                dispatch(fetchCategoriesList({}));
                addCrisp(parsedData)
                // intitSnoFlake(parsedData)
                // push data to simplebase
                // updateSimpleBaseAttributes(parsedData);

                dispatch(getPermissions({userId: parsedData?.userId, token: session.idToken.jwtToken}))
                // if(checkSubdomain(res.workspaceDomain ? res?.workspaceDomain : null) === false){
                //     window.location.href= api.ustRedirectLink
                // }

                // pre load dashboard

                // dispatch(setNextPageAction(1));
                // dispatch(
                //     getAllDirAction({
                //         workspace: res
                //             ? res?._id
                //             : res,
                //         parentDirectory: null,
                //         userId: parsedData?.userId,
                //     }),
                // );
                // dispatch(getAllSubscriptions({}))

                // const { lowerValue, upperValue} = generateMonthlyUTCFromNow();
                // const permissions = generatePermissionJson(parsedData.subscription);
                // store.dispatch({ type: SET_PERMISSIONS, payload: permissions });
                // const info = {
                //     userId: parsedData.userId,
                //     endDate: upperValue,
                //     startDate: lowerValue,
                //     workspace: null
                // };
                // console.count("bootApp");
                // store.dispatch({type: GET_MONTHLY_VIDEOS_REQUEST, payload: info});
            })

            // dispatch(fetchWorkspace({userId: parsedData?.userId, token: session.idToken.jwtToken}))


        })
        .catch(err => {
            console.log('error while user authentication', err);
            window.location.href = api.appUrl;
        });
    // store.dispatch({ type: LOADED_SUCCESS });

    // store.dispatch({type: LOADED_SUCCESS});

    // boot(res.user_id, res.first_name + res.last_name, jwt);
};

declare let window: any;

const App: React.FC<IProps> = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        bootApp(dispatch);
    }, []);

  const loaded = useSelector((state: RootState) => state.appReducer.loaded);
  if (!loaded) {
    return <AppLoader />;
  } else {
    return (
        <Suspense
            fallback={
                <AppLoader />
            }
        >
            <Switch>
                <PrivateRoute exact component={Dashboard} path="/dashboard" />;
                <PrivateRoute exact component={Agency} path="/agency" />;
                <PrivateRoute exact component={Template} path="/templates" />;
                <PrivateRoute exact component={AppsComponent} path="/apps" />;
                <PrivateRoute exact component={CommentsPage} path="/comments" />
                <PrivateRoute exact component={AutomationPage} path="/automation" />
                <PrivateRoute exact component={Settings} path="/settings" />;
                {/*<PrivateRoute exact component={BusinessTools} path="/businesstools" />;*/}
                <PrivateRoute exact component={Accounts} path="/accounts" />;
                {/*<PrivateRoute exact component={DashboardHtml} path="/DashboardHtml" />;*/}
                {/*<PrivateRoute exact component={Tools} path="/tools" />;*/}
                <PublicRoute exact component={Login} path="/" />;
                <Redirect from="*" to="/" />;
            </Switch>
        </Suspense>

        // <Switch>
        //
        //     <Suspense
        //         fallback={
        //             <AppLoader />
        //         }
        //     >
        //         <PrivateRoute exact component={Dashboard} path="/dashboard" />;
        //     </Suspense>
        //     <Suspense
        //         fallback={
        //             <AppLoader />
        //         }
        //     >
        //         <PrivateRoute exact component={Template} path="/templates" />;
        //     </Suspense>
        //     <Suspense
        //         fallback={
        //             <AppLoader />
        //         }
        //     >
        //         <PrivateRoute exact component={AppsComponent} path="/Apps" />;
        //     </Suspense>
        //     <Suspense
        //         fallback={
        //             <AppLoader />
        //         }
        //     >
        //         <PrivateRoute exact component={CommentsPage} path="/comments" />;
        //     </Suspense>
        //     <Suspense
        //         fallback={
        //             <AppLoader />
        //         }
        //     >
        //         <PrivateRoute exact component={Settings} path="/settings" />;
        //     </Suspense>
        //     <Suspense
        //         fallback={
        //             <AppLoader />
        //         }
        //     >
        //         <PrivateRoute exact component={Accounts} path="/accounts" />;
        //     </Suspense>
        //   {/*<PrivateRoute exact component={BusinessTools} path="/businesstools" />;*/}
        //
        //   {/*<PrivateRoute exact component={DashboardHtml} path="/DashboardHtml" />;*/}
        //   {/*<PrivateRoute exact component={Tools} path="/tools" />;*/}
        //   <Redirect from="*" to="/" />;
        // </Switch>
    );
  }
};

export default App;
