import {SET_PROFILE, UPDATE_ON_BOARDING, SET_USER_PERMISSIONS} from './types';
import { Reducer } from "redux";
import { profileType } from './DataProps';
import { RootState } from '../store';

interface actionType {
  type: string,
  payload?: any
}

interface initalStateProps {
  isAuthenticated: boolean;
  profile: profileType | null;
  onBoardingData: any | null;
  userPermissions: any | null;
}
const initState: initalStateProps = {
  isAuthenticated: true,
  profile: null,
  onBoardingData: null,
  userPermissions: null
}

const authReducer: Reducer<initalStateProps, actionType> = (state = initState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return Object.assign({}, state, {
        isAuthenticated: true,
        profile: action.payload
      })
    case UPDATE_ON_BOARDING:
      return Object.assign({}, state, {
        isAuthenticated: true,
        profile: action.payload
      })
    case SET_USER_PERMISSIONS:
      return Object.assign({}, state, {
       userPermissions: action.payload
      })

    default:
      return state;
  }
}
export const getCurrentUser = (state: RootState) => state.authReducer.profile
export default authReducer;
