/**
 *
 * @param {*} data
 * Default render video model which will contain all the nesassary attributes for the payload used in backend.
 */
export class RenderVideoModel {

    id: string;
    src: string;
    xpos: number;
    ypos: number;
    duration: number;
    width: number;
    height: number;
    start: number;
    end: number;
    showtime: number;
    muted: boolean;
    rotation: number;
    volume: number;

    constructor(data: any) {
        this.id = data.id
        this.src = data.file
        this.xpos = parseFloat(data.xPosition)
        this.ypos = parseFloat(data.yPosition)
        this.duration = data.duration
        this.width = data.currentWidth
        this.height = data.currentHeight
        this.start = data.targetStart
        this.end = data.targetEnd
        this.showtime = data.showTime
        this.muted = data.muted;
        this.rotation = data.rotation;
        this.volume = data.volume;
    }

}


