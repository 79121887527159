import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';

import './index.css';
import api from "./config";
import {initAmplitude} from './helper/amplitude'



initAmplitude();
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <link rel="preload" href={api.iFrameUrl} as="iframe" />
      <Helmet>
        <meta name="description" content={api.APP === 'VIDPROPOSALS' ? 'Send video proposals and close deals faster' : 'Record and share videos'} />
        <meta charSet="utf-8" />
        <title>Dashboard - {api.APP}</title>
        <link rel="icon" href={api.APP_FAVICON} />
        <script type="text/javascript" async>
          {`
            var _tip = _tip || [];
            (function (d, s, id) {
            var js, tjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.async = true;
            js.src = d.location.protocol + '//app.truconversion.com/ti-js/${api.TC_JS_PATH}';
            tjs.parentNode.insertBefore(js, tjs);
          }(document, 'script', 'ti-js'));
        `}
        </script>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${api.GA_WEBSITE_ID}`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', '${api.GA_WEBSITE_ID}');
          `}
          
        </script>
      </Helmet>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

function noop() { }
if (process.env.REACT_APP_ENV !== 'dev') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
  console.count = noop;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
