/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
import { SagaIterator } from 'redux-saga'
import { all, takeEvery, fork, call } from 'redux-saga/effects';
import {GET_STATS} from './types';
import { callVumuServerlessApi } from '../../helper/api.helper';

async function fetchStatisticsReq(payload:any, token:any) {
	return callVumuServerlessApi({ url: '/get-stats', payload, method: 'POST', token });
}
export function* fetchVideoStats() {
	yield takeEvery(GET_STATS, function* ({ payload, resolve, reject }: any): SagaIterator  {
		try {
			const { token } = payload;
			delete payload.token;
			const response = yield call(fetchStatisticsReq, payload, token);
            resolve(response);
		} catch (error) {
			console.log({ error });
            reject(null);
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(fetchVideoStats),
	]);
}
