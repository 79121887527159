import { all } from 'redux-saga/effects';
import authSaga from '../auth/saga';
import fileBrowserSaga from '../file-browser/saga';
import appSaga from '../app/saga';
import statsSaga from '../stats/saga';
import accountSaga from '../accounts/saga';

export default function* Sagas() {
  yield all([
    authSaga(),
    fileBrowserSaga(),
    appSaga(),
    statsSaga(),
    accountSaga()
  ]);
}