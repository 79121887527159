import { notification } from 'antd';
import config from '../config';

const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: (
      <a href={description.navigateUrl} target="_blank" rel="noreferrer" ><span>{description.message}</span></a>
    ),
    duration: 5,
  });
};

const connection = async (clientId, username, JWTtoken) => {
  let socket = null;
  let pingPongInterval = null;
  let totaltries = 10;
  const pingPong = () => {
    if (!socket || socket.readyState !== 1) {
      connect();
      return;
    }
    socket.send('heartbeat');
    console.log('heartbeat');
  };
  const connect = () => {
    if (pingPongInterval) clearInterval(pingPongInterval);
    //Set the websocket server URL
    console.log('socket: ', JWTtoken, clientId);
    var websocketurl =
      config.SOCKET_URL +
      (JWTtoken ? 'Authorizer=' + JWTtoken : 'Userid=' + clientId);
    //Create the WebSocket object (web socket echo test service provided by websocket.org)
    socket = new WebSocket(websocketurl);
    pingPongInterval = setInterval(pingPong, 15000);
    //This function is called when the websocket connection is opened
    socket.onopen = function () {
      console.info('Socket is connected');
      // index.dispatch(setConnected({ value: true }));
    };
    //This function is called when the websocket connection is closed
    socket.onclose = function () {
      totaltries--;
      console.info('Socket connection closed, reconnecting......');
      if (totaltries > 0) {
        connect();
        // index.dispatch(setReConnecting());
      }
    };
    socket.onerror = async event => {
      console.log({ event });
      connect();
      // index.dispatch(setReConnecting());
    };
    //This function is called when the websocket receives a message. It is passed the message object as its only parameter
    socket.onmessage = async (messageRecieved) => {
      const JSONmessage = JSON.parse(messageRecieved.data.toString('utf8'));
      console.log('socket', JSONmessage)
      if (JSONmessage.messageType === 'VIEWS' ||  JSONmessage.messageType === 'CLICKED_PLAY' || JSONmessage.messageType === 'COMMENTED') {
        openNotificationWithIcon(
          'success',
          'New Notification',
          JSONmessage.data,
        );
        // dispatch to add the notification in notifications redux
      }
    };
  };
  if (!window.WebSocket) {
    //If the user's browser does not support WebSockets, give an alert message
    alert('Your browser does not support the WebSocket API!');
  } else {
    console.info('Socket connecting......');
    connect();
  }
};

export default connection;
