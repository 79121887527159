import axios from 'axios';
import config from './../config';

const axiosRenderApi = axios.create({
  baseURL: config.RENDER_API_URL
})

axiosRenderApi.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    resolve(response.data);
  });
}, error => {

  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = config.appUrl;

  } else {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
})

const axiosDashboardServerless = axios.create({
  baseURL: config.DASHBOARD_SERVERLESS_API
});

axiosDashboardServerless.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    console.log({response});
    resolve(typeof response.data.data === 'string' ?  JSON.parse(response.data.data) : response.data.data);
  });
}, error => {
  console.log(error);
  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
    console.log(error.response.status);
  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = config.appUrl;

  } else {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
})

const axiosStudioApi = axios.create({
  baseURL: config.studioServer
})

axiosStudioApi.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    resolve(response);
  });
}, error => {

  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = config.appUrl;

  } else {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
})

const axiosVumuServerless = axios.create({
  baseURL: config.VUMU_API_GATEWAY
})

axiosVumuServerless.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    resolve(response);
  });
}, error => {
  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = config.appUrl;

  } else {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
})

const axiosUst = axios.create({
  baseURL: config.UST_API_GATEWAY
})

axiosUst.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    resolve(response);
  });
}, error => {

  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = config.appUrl;

  } else {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
})

const authServiceAxios = axios.create({
  baseURL: config.AUTH_SERVICE_API
})

authServiceAxios.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    resolve(response.data);
  });
}, error => {

  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = config.appUrl;

  } else {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
})

const axiosDashboardServiceApi = axios.create({
  baseURL: config.DASHBOARD_SERVICE_API
})

axiosDashboardServiceApi.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    resolve(response.data);
  });
}, error => {

  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = config.appUrl;

  } else {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
})
export {
  axiosRenderApi,
  axiosDashboardServerless,
  axiosDashboardServiceApi,
  axiosStudioApi,
  axiosVumuServerless,
  axiosUst,
  authServiceAxios
}
