import React from 'react';
import './style.scss';

export default function () {
  return <>
    <div className="container-loading"><div className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    </div>
    </>
}
