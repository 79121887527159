import {SagaIterator} from 'redux-saga';
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {message} from 'antd';


import {
  callApiGateway, callApiTokenPayload, callDashboardServiceApi,
  callRenderApi,
  callStudioApi,
  callUstApi,
  callVumuServerlessApi
} from '../../helper/api.helper';
import * as types from './types';
import api from '../../config';
import {
  createPayloadForBackend
} from '../../utils/utils';

//saga calls
export function* fetchAllProAndDirSaga() {
  yield takeEvery(types.FETCH_ALL_PRO_AND_DIR, function* (payload: any): SagaIterator {
    try {
      const payloadData = payload.payload
      yield put({ type: types.FB_LOADING, payload: true })
      // let maxUpdateDate ;
      // let localData =  getProjectsLocalStorage(payloadData.userId)
      // if(localData && localData.response){
      //   maxUpdateDate = localData.maxUpdateDate
      //   // yield put({
      //   //   type: types.FETCH_ALL_PRO_AND_DIR_SUCCESS,
      //   //   payload: localData.response
      //   // });
      //   // message?.destroy();
      //   // return
      // }

      let response = yield call(fetchAllProAndDirReq, {...payloadData, maxUpdateDate: null});
      // if(response[0]['projects'] && response[0]['projects'].length === 0) {
      //   yield put({
      //     type: types.FETCH_ALL_PRO_AND_DIR_SUCCESS,
      //     payload: localData.response
      //   });
      //   yield put({ type: types.FB_LOADING, payload: false })
      //   return
      // }
      // var mxDate  = getMaxDate(response[0]['projects'])
      // if (!localData){
      //   yield put({ type: types.FB_LOADING, payload: true })
      //   setProjectsLocalStorage(payloadData.userId, {response, maxUpdateDate: mxDate})
      //   //check yield intersect the payloads
      //   yield put({
      //     type: types.FETCH_ALL_PRO_AND_DIR_SUCCESS,
      //     payload: response
      //   });
      //   message?.destroy();
      //   yield put({ type: types.FB_LOADING, payload: false })
      //   return
      // }
      //
      // const oldArray = localData.response[0]['projects'];
      // const newArray = response[0]['projects'];
      // response[0]['projects'] = _mergeResponseFromServer(oldArray, newArray)
      // setProjectsLocalStorage(payloadData.userId, {response, maxUpdateDate: mxDate})
      //check yield intersect the payloads
      yield put({
        type: types.FETCH_ALL_PRO_AND_DIR_SUCCESS,
        payload: response
      });
      message?.destroy();
      // yield put({ type: types.FB_LOADING, payload: false })
    } catch (error) {
      // yield put({ type: types.FB_LOADING, payload: false })
      debugger;
      message.error("Error during fetching the projects");
      console.log(error)
    }
  });
}

export function* createNewDirSaga() {
  yield takeEvery(types.CREATE_NEW_DIR, function* (payload: any): SagaIterator {
    try {
      yield put({ type: types.FB_LOADING, payload: true });
      const response = yield call(createNewDirReq, payload.payload);
      console.log({ response });
      yield put({
        type: types.CREATE_NEW_DIR_SUCCESS,
        payload: response
      });
      yield put({ type: types.FB_LOADING, payload: false })
      message.destroy();
    } catch (error) {
      message.error("Error creating folder");
    }
  });
}

export function* updateDirSaga() {
  yield takeEvery(types.UPDATE_DIR, function* (payload: any): SagaIterator {
    try {
      yield put({
        type: types.UPDATE_DIR_SUCCESS,
        payload: payload.payload
      });
      yield put({ type: types.FB_LOADING, payload: true })
      const response = yield call(updateDirReq, payload.payload);
      console.log({ response });
      message.destroy();
      yield put({ type: types.FB_LOADING, payload: false })
    } catch (error) {
      message.error("Error updating directory");
    }
  });
}

export function* deleteDirSaga() {
  yield takeEvery(types.DELETE_DIR, function* (payload: any): SagaIterator {
    try {
      const response = yield call(deleteDirReq, payload.payload);
      if (!response) {
        message.error('Folder is not empty, Please remove the files from folder before deleting');
      } else {
        yield put({
          type: types.DELETE_DIR_SUCCESS,
          payload: response
        });
      }
    } catch (error) {
      console.log({ error });

      message.error("Error deleting directory");
    }
  });
}

export function* updateProjectSaga() {
  yield takeEvery(types.UPDATE_PROJECT, function* (payload: any): SagaIterator {
    try {
      yield put({
        type: types.UPDATE_PROJECT_SUCCESS,
        payload: payload.payload
      });
      const response = yield call(updateProjectReq, payload.payload);
      message.destroy();
      console.log({ response });
    } catch (error) {
      message.error("Error updating project");
    }
  });
}

export function* updateProjectVumuServerlessSaga() {
  yield takeEvery(types.UPDATE_PROJECT_VUMU_SERVERLESS, function* (payload: any): SagaIterator {
    try {
      const response = yield call(updateProjectVumuServerlessReq, payload.payload);
      message.destroy();
      console.log({ response });
    } catch (error) {
      message.error("Error updating project");
    }
  });
}

export function* deleteProjectSaga() {
  yield takeEvery(types.DELETE_PROJECT, function* (payload: any): SagaIterator {
    try {
      yield put({
        type: types.DELETE_PROJECT_SUCCESS,
        payload: payload.payload
      });
      const response = yield call(deleteProjectReq, payload.payload);
      console.log({ response });
    } catch (error) {
      console.log({ error });
      message.error("Error deleting project");
    }
  });
}

export function* addProjectSaga() {
  yield takeEvery(types.ADD_PROJECT, function* (payload: any): SagaIterator {
    try {
      yield put({ type: types.FB_LOADING, payload: true })
      if (api.redirect) {
        const response = yield call(addProjectReq, payload.payload);
        console.log({ response });
        yield window.location.href = api.addContentCTA.replace('{id}', response._id);
        yield put({ type: types.FB_LOADING, payload: false })
        message.destroy();
      }
      else {
        yield put({
          type: types.SHOW_IFRAME, payload: { state: true }
        });
        yield put({ type: types.FB_LOADING, payload: false });
        message.destroy();
      };
    } catch (error) {
      console.log({ error });
      message.error("Error creating project");
    }
  });
}

export function* addVumuProjectByUpload() {
  yield takeEvery(types.ADD_VUMU_PROJECT_UPLOAD, function* (payload: any): SagaIterator {
    try {
      yield put({ type: types.FB_LOADING, payload: true })
      const response = yield call(addRecordingsVumu, payload.payload);
      console.log({ response });
      const projectPayload = JSON.parse(response.data.data)
      projectPayload['id'] = projectPayload._id
      projectPayload['name'] = projectPayload.title
      yield put({ type: types.ADD_PROJECT_LOCAL, payload: projectPayload })
      // yield window.location.href = api.addContentCTA.replace('{id}', response._id);
      yield put({ type: types.FB_LOADING, payload: false })
      // REDIRECTION ATTACH
      message.destroy();

    } catch (error) {
      console.log({ error });
      message.error("Error creating project");
    }
  });
}

export function* renderVideoSaga() {
  yield takeEvery(types.RENDER_VIDEO, function* (payload: any): SagaIterator {
    try {
      let metaDataResp = yield call(getMetaDataRequest, payload['payload']);
      console.log({ metaDataResp });
      const metaData = metaDataResp['metaData']
      const renderingPayload = yield call(createPayloadForBackend, metaData.tracks,
        metaData.maxDuration,
        metaData.zoom,
        metaData.muted,
        metaData.subtitleStyle,
        payload['payload']['_id'],
        {
          canvasWidth: metaData.studioPlayerWidth,
          canvasHeight: metaData.studioPlayerHeight,
          canvasColor: metaData.canvasColor,
          dimension: metaData.socialDimension
        },
      );
      const response = yield call(renderVideoRequest, renderingPayload);
      console.log({ metaData, renderingPayload, response });
      message.success('Rendering started, We will notify you with email...');
    } catch (error) {
      console.log({ error });
      message.error("Rendering call failed");
    }
  });
}



export function* addProjectKeeviSaga() {
  yield takeEvery(types.ADD_PROJECT_KEEVI, function* (payload: any): SagaIterator {
    try {
      yield put({ type: types.FB_LOADING, payload: true })
      const response = yield call(addProjectReq, payload.payload);
      console.log({ response });
      yield put({ type: types.ADD_PROJECT_KEEVI_SUCCESS, payload: response })
      yield put({ type: types.FB_LOADING, payload: false })
      message.destroy();
    } catch (error) {
      console.log({ error });
      message.destroy();
      message.error("Error creating project");
    }
  });
}

export function* addProjectVumuSaga() {
  yield takeEvery(types.ADD_PROJECT_VUMU, function* (payload: any): SagaIterator {
    try {
      yield put({ type: types.FB_LOADING, payload: true })
      const response = yield call(addRecordingsVumu, payload.payload);
      yield put({ type: types.ADD_PROJECT_VUMU_SUCCESS, payload: payload.payload })
      yield put({ type: types.FB_LOADING, payload: false })
      console.info({response})
      payload.resolve(response);
      // payload.resolve(payload.payload._id);
      message.destroy();
    } catch (error) {
      console.log({ error });
      message.destroy();
      message.error("Error creating project");
      payload.reject();
    }
  });
}

export function* addBlogVumuSaga() {
  yield takeEvery(types.ADD_PROJECT_BLOG_VUMU, function* (payload: any): SagaIterator {
    try {
      const response = yield call(addBlogVumu, payload.payload);

      payload.resolve(response);
      message.destroy();
    } catch (error) {
      console.log({ error });
      message.destroy();
      payload.reject();
    }
  });
}

export function* fetchBlogVumuSaga() {
  yield takeEvery(types.FETCH_PROJECT_BLOG_VUMU, function* (payload: any): SagaIterator {
    try {
      const response = yield call(fetchBlogVumu, payload.payload);
      if(response && response.data && response.data.data) {
        payload.resolve(response.data.data);
      } else {
        payload.reject();
      }
    } catch (error) {
      console.log({ error });
      message.destroy();
      payload.reject();
    }
  });
}

export function* updateProjectVumuSaga() {
  yield takeEvery(types.UPDATE_PROJECT_VUMU, function* ({payload}: any): SagaIterator {
    try {
      yield put({ type: types.FB_LOADING, payload: true })
      const response = yield call(updateRecordingsVumu, payload);
      console.log({ response });
      yield put({ type: types.UPDATE_PROJECT_VUMU_SUCCESS, payload: payload })
      yield put({ type: types.FB_LOADING, payload: false })
      message.destroy();
    } catch (error) {
      console.log({ error });
      message.destroy();
      message.error("Error creating project");
    }
  });
}

export function* fetchTemplatesSaga(){
  yield takeEvery(types.FETCH_TEMPLATES, function* (payload: any): SagaIterator {
    const response = yield call(fetchTemplatesApiCall, payload.payload);
    message.destroy();
    console.log({ response });
    yield put({
      type: types.FETCH_TEMPLATES_SUCCESS,
      payload: response
    });
  })
}

export function* fetchCategoriesListSaga(){
  yield takeEvery(types.FETCH_CATEGORIES, function* (payload: any): SagaIterator {
    const response = yield call(fetchCategoriesList, payload.payload);
    message.destroy();
    yield put({
      type: types.FETCH_CATEGORIES_SUCCESS,
      payload: response
    });
  })
}
export function* fetchWorkspaceSaga() {
  yield takeEvery(types.FETCH_WORKSPACE_ONLY, function* (payload: any): SagaIterator {
    try {
      const response = yield call(fetchWorkspace, {...payload.payload});
      console.log({ response });
      if(!response) return
      yield put({ type: types.UPDATE_WORKSPACE, payload: response })
      payload.resolve(response)
      message.destroy();
    } catch (error) {
      console.log({ error });
      message.destroy();
      message.error("Error creating project");
      payload.reject()
    }
  });
}


export function* fetchAssociatedWorkspacesSaga() {
  yield takeEvery(types.FETCH_WORKSPACE_ASSOCIATION, function* (payload: any): SagaIterator {
    try {
      const reqData = payload.payload
      const response = yield call(fetchWorkspaceAssociation, {...reqData});
      console.log({ response });
      if(!response) return
      const userPersonalWorkspace = response.find((workspace: any) => (workspace.userId === reqData.userId && workspace.workspaceType === 'MASTER'));
      // parse response and store the
      yield put({ type: types.UPDATE_WORKSPACE, payload: userPersonalWorkspace })
      yield put({ type: types.SET_WORKSPACE_ASSOCIATION, payload: response })
      payload.resolve(response)
      message.destroy();
    } catch (error) {
      console.log({ error });
      message.destroy();
      message.error("Error creating project");
      payload.reject()
    }
  });
}
export function* createOnboarding() {
  yield takeEvery(types.CREATE_ONBOARDING, function* (payload: any): SagaIterator {
    try {
      const response = yield call(createOnboardingAPi, {...payload.payload});
      console.log({ response });
      if(!response) return
      // yield put({ type: types.ADD_WORKSPACE_ONBOARDING, payload: response })
      payload.resolve(response)
      message.destroy();
    } catch (error) {
      console.log({ error });
      message.destroy();
      message.error("Error creating project");
      payload.reject()
    }
  });
}
export function* updateWorkspaceSaga() {
  yield takeEvery(types.CALL_UPDATE_WORKSPACE, function* (payload: any): SagaIterator {
    try {
      const response = yield call(updateWorkspaceApi, payload.payload);
      console.log({ response });
      if(!response) return
      // yield put({ type: types.UPDATE_WORKSPACE, payload: response })
      payload.resolve(response)
      message.destroy();
    } catch (error) {
      console.log({ error });
      message.destroy();
      message.error("Error creating project");
      payload.reject()
    }
  });
}
export function* updateDomainSaga() {
  yield takeEvery(types.CALL_UPDATE_DOMAIN, function* (payload: any): SagaIterator {
    try {
      const response = yield call(updateDomainApi, payload.payload);
      console.log({ response });
      if(!response) return
      // yield put({ type: types.UPDATE_WORKSPACE, payload: response })
      message.destroy();
      payload.resolve(response)
    } catch (error) {
      console.log({ error });
      message.destroy();
      message.error("Error creating project");
      payload.reject()
    }
  });
}

export function* fetchStatisticsSaga(){
  yield takeEvery(types.GET_STATISTICS_REQUEST, function* ({ payload }: any): SagaIterator {
    try {
      const response = yield call(fetchStatisticsReq, payload);
      yield put({
        type: types.GET_STATISTICS_REQUEST_SUCCESS,
        payload: response.length > 0 ? response[0] : null
      });
    } catch (error) {
      console.log({ error });

    }
  });
}

export function* fetchAllDirSaga() {
  yield takeEvery(types.FETCH_ALL_DIR_REQ, function* (payload: any): SagaIterator {
    try {
      const payloadData = payload.payload
      // yield put({ type: types.FB_LOADING, payload: true })

      let response = yield call(fetchAllDirReq, {...payloadData});
      yield put({
        type: types.FETCH_ALL_DIR_SUCCESS,
        payload: response
      });
      message?.destroy();
      yield put({ type: types.FB_LOADING, payload: false })
    } catch (error) {
      yield put({ type: types.FB_LOADING, payload: false })
      debugger;
      message.error("Error during fetching the projects");
      console.log(error)
    }
  });
}

export function* fetchAllProSaga() {
  yield takeEvery(types.FETCH_ALL_PRO_REQ, function* (payload: any): SagaIterator {
    try {
      const payloadData = payload.payload
      // yield put({ type: types.FB_LOADING, payload: true })

      let response = yield call(fetchAllProReq, {...payloadData});
      yield put({
        type: types.FETCH_ALL_PRO_SUCCESS,
        payload: response
      });
      message?.destroy();
      // yield put({ type: types.FB_LOADING, payload: false })
    } catch (error) {
      // yield put({ type: types.FB_LOADING, payload: false })
      debugger;
      message.error("Error during fetching the projects");
      console.log(error)
    }
  });
}

export function* fetchAllDirWorkspaceSaga() {
  yield takeEvery(types.FETCH_ALL_DIR_WRK_REQ, function* (payload: any): SagaIterator {
    try {
      const payloadData = payload.payload
      let response = yield call(fetchAllDirWrkReq, {...payloadData});
      yield put({
        type: types.FETCH_ALL_DIR_WRK_SUCCESS,
        payload: response
      });
      message?.destroy();
    } catch (error) {
      debugger;
      message.error("Error during fetching the projects");
      console.log(error)
    }
  });
}

export function* countAllCommentsSaga() {
  yield takeEvery(types.GET_COMMENTS_COUNT_REQ, function* ({ payload, resolve, reject }: any): SagaIterator {
    try {
      let response = yield call(getCommentCountVumu, payload);
      yield put({
        type: types.GET_COMMENTS_COUNT_SUCCESS,
        payload: response['data']['data']
      });
      resolve();
      message?.destroy();
    } catch (error) {
      debugger;
      message.error("Error during fetching the projects");
      console.log(error)
      reject();
    }
  });
}

export function* fetchWorkspaceBUserSaga() {
  yield takeEvery(types.GET_USER_B_WORKSPACE, function* (payload: any): SagaIterator {
    try {
      const response = yield call(fetchWorkspace, payload.payload);
      if(!response) return;
      payload.resolve(response)
      message.destroy();
    } catch (error) {
      console.log({ error });
      message.destroy();
      message.error("Error fetching workspace");
      payload.reject()
    }
  });
}

//api Calls
async function fetchAllProAndDirReq(payload: any) {
  return await callApiGateway({ url: '/fetch-all-pro-dir', payload, method: 'POST' })
}

async function createNewDirReq(payload: any) {
  return await callApiGateway({ url: '/add-directory', payload, method: 'POST' })
}

async function updateDirReq(payload: any) {
  return await callApiGateway({ url: '/update-directory', payload, method: 'POST' })
}

async function deleteDirReq(payload: any) {
  return await callApiGateway({ url: '/delete-directory', payload, method: 'POST' })
}

async function updateProjectReq(payload: any) {
  return await callApiGateway({ url: '/update-project', payload, method: 'POST' })
}

async function updateProjectVumuServerlessReq(payload: any) {
  return await callVumuServerlessApi({ url: '/update-recording', payload, method: 'POST' })
}

async function deleteProjectReq(payload: any) {
  return await callApiGateway({ url: '/delete-project', payload, method: 'POST' })
}

async function addProjectReq(payload: any) {
  return await callApiGateway({ url: '/add-project', payload, method: 'POST' })
}
async function addRecordingsVumu(payload: any) {
  // sample payload
  // _id: id,
  // videoUrl: `${config.STREAM_URL}/${s3Key}`,
  // createdBy: currentUserId || null,
  return await callVumuServerlessApi({ url: '/add-recording', payload, method: 'POST' })
}

async function addBlogVumu(payload: any) {
  return await callVumuServerlessApi({ url: '/update-blog', payload, method: 'POST' })
}

async function fetchBlogVumu(payload: any) {
  return await callVumuServerlessApi({ url: '/get-blog', payload, method: 'POST' })
}

async function renderVideoRequest(payload: any) {
  return await callRenderApi({ url: '/render-job', payload, method: 'POST' })
}

async function getMetaDataRequest(payload: any) {
  return await callStudioApi({ url: `/metadata/metadata/${payload._id}`, payload, method: 'GET' })
}

async function updateRecordingsVumu(payload: any) {
  return await callVumuServerlessApi({ url: '/update-recording', payload, method: 'POST' })
}

async function fetchTemplatesApiCall(payload:any) {
  return await callDashboardServiceApi({ url: 'projects/fetch-templates', payload, method: 'POST' })

}

async function fetchCategoriesList(payload:any) {
  return await callDashboardServiceApi({ url: 'categories/fetch-categories', payload, method: 'POST' })
}

async function fetchStatisticsReq(payload:any) {
  return await callApiGateway({ url: '/get-statistics', payload, method: 'POST' })
}

async function fetchWorkspace(payload:any) {
    return await callApiTokenPayload({ url: '/get-workspace', payload, method: 'POST' })
}
async function fetchWorkspaceAssociation(payload:any) {
  return await callDashboardServiceApi({ url: 'workspace/fetch-workspaces', payload, method: 'POST' })
}
async function updateWorkspaceApi(payload:any) {
  return await callApiGateway({ url: '/update-workspace', payload, method: 'POST' })
}
async function updateDomainApi(payload:any) {
  return await callApiGateway({ url: '/update-domain', payload, method: 'POST' })
}
async function createOnboardingAPi(payload:any) {
  return await callApiGateway({ url: '/create-onboarding', payload, method: 'POST' })
}

async function fetchAllDirReq(payload: any) {
  return await callDashboardServiceApi({ url: 'directories/fetch-directories', payload, method: 'POST' })
}

async function fetchAllProReq(payload: any) {
  return await callDashboardServiceApi({ url: 'projects/fetch-projects', payload, method: 'POST' })
}

async function fetchAllDirWrkReq(payload: any) {
  return await callDashboardServiceApi({ url: 'directories/fetch-directories-workspace', payload, method: 'POST' })
}

async function getCommentCountVumu(payload: any) {
  return await callVumuServerlessApi({ url: '/count-comments', payload, method: 'POST' })
}

export default function* fileBrowserSaga() {
  yield all([
    fork(fetchAllProAndDirSaga),
    fork(createNewDirSaga),
    fork(updateDirSaga),
    fork(deleteDirSaga),
    fork(updateProjectSaga),
    fork(updateProjectVumuServerlessSaga),
    fork(deleteProjectSaga),
    fork(addProjectSaga),
    fork(addVumuProjectByUpload),
    fork(addBlogVumuSaga),
    fork(fetchBlogVumuSaga),
    fork(renderVideoSaga),
    fork(addProjectKeeviSaga),
    fork(addProjectVumuSaga),
    fork(updateProjectVumuSaga),
    fork(fetchTemplatesSaga),
    fork(fetchCategoriesListSaga),
    fork(fetchWorkspaceSaga),
    fork(updateWorkspaceSaga),
    fork(fetchCategoriesListSaga),
    fork(fetchStatisticsSaga),
    fork(createOnboarding),
    fork(updateDomainSaga),
    fork(fetchAllDirSaga),
    fork(fetchAllProSaga),
    fork(fetchAllDirWorkspaceSaga),
    fork(countAllCommentsSaga),
    fork(fetchWorkspaceBUserSaga),
    fork(fetchAssociatedWorkspacesSaga),
  ]);
}
