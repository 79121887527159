import { profileType } from "../redux/auth/DataProps";

export class UserModel implements profileType {
  //Fields
  userId: string;
  cognitoUserName: string;
  fullName: string;
  parentAccount: string;
  externalApp?: string;
  external_id?: string;
  subscription: string;
  email: string;
  emailVerified: boolean;
  picture: string;
  sub: string;
  jwtToken: string;
  onBoard: boolean;
  groups: Array<string> = [];
  packages: undefined | string;

  constructor (data: any, token: any){
    this.userId = data['preferred_username']
    this.cognitoUserName = data['Username']
    this.fullName = data['name']
    this.parentAccount = data['custom:parentAccount']
    this.externalApp = data['custom:externalApp']
    this.external_id = data['custom:external_id']
    this.subscription = data['custom:subscription']
    this.packages = data['custom:packages']
    this.email = data['email']
    this.emailVerified = data['email_verified']
    this.picture = data['picture']
    this.sub = data['sub']
    this.onBoard = data['custom:onBoard'] || true
    this.groups = data.idToken.payload['cognito:groups'];
    this.jwtToken = token
  }}

export const getFirstNameFromEmail = (input: any) =>{
  // Regular expression to validate email addresses
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  // Check if the input matches the email pattern
  if (!emailRegex.test(input)) {
      return input;
  }
  
  // Extract the part before the "@" symbol
  let localPart = input.split('@')[0];
  
  // Extract the first part before the "." symbol
  let firstName = localPart.split('.')[0];
  
  // Capitalize the first letter of the first name
  firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
  
  return firstName;
}