import Cookies from 'js-cookie';
import {callRenderApi} from './api.helper';
import { store } from '../redux/store';

export const getCurrentUser = () => {
  const currentUser = Cookies.get("currentUser");
  return (currentUser) ? JSON.parse(currentUser) : {};
};

export const setCurrentUser = (userProfile: Object) => {
  Cookies.set("currentUser", JSON.stringify(userProfile));
};

export const removeUser = () => {
  Cookies.remove("currentUser");
};

export const setUToken = (token: string) => {
  Cookies.set("userToken", token)
};

export const getUToken = () => {
  const state = store.getState();
  const jwtToken = state.authReducer.profile?.jwtToken;
  return jwtToken;
};

export const removeUToken = () => {
  Cookies.remove("userToken");
};

export const removeAllCookies = () => {
  removeUser();
  removeUToken();
};

export const fetchCurrentUser = async () => {
  const res = await callRenderApi({ url: '/profile/', payload: {}, method: 'GET' });
  return res;
}