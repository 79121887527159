import {
  LOADED_SUCCESS,
  LOADING_APP,
  UPGRADE_MODAL_VISIBLE,
  GET_MONTHLY_VIDEOS_REQUEST,
  GET_ALL_SUBSCRIPTIONS,
  SET_CONNECTED_APPS
} from './types';
import {SHOW_PLAY_BOOK_POPUP} from "../file-browser/types";

export const AppLoaded = (payload: any) => (dispatch: any) => {
  dispatch({ type: LOADED_SUCCESS })
}

export const AppLoading = (payload: any) => (dispatch: any) => {
  dispatch({ type: LOADING_APP })
}

export const upgradeModalVisibiltyAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: UPGRADE_MODAL_VISIBLE, payload });
}

export const showPlaybooksPopup = (payload: any) => (dispatch: any) => {
  dispatch({ type: SHOW_PLAY_BOOK_POPUP, payload });
}

export const getMonthlyVideosAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: GET_MONTHLY_VIDEOS_REQUEST, payload })
}

export const setConnectedApplications = (payload: any) => (dispatch: any) => {
  dispatch({ type: SET_CONNECTED_APPS, payload })
}

export const getAllSubscriptions = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: GET_ALL_SUBSCRIPTIONS,  payload, resolve, reject });
  })
}
